import React from "react";
import { ReactComponent as Alert } from "../../assets/images/svg/alert.svg";
import { ReactComponent as Check } from "../../assets/images/svg/check.svg";

export function Errors(props) {
  return (
    <div
      className={props.className}
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Alert color={props.textColor} />
      <span style={{ color: props.textColor }}>{props.message}</span>
    </div>
  );
}

export function Success(props) {
  return (
    <div className="success-message">
      <Check fill="#27ae5f" />
      <span>{props.message}</span>
    </div>
  );
}
