import React from "react";
import { Link } from "react-router-dom";

export default function PageSwitcher(props) {
  return (
    <div className="switcher-container">
      <div className="switcher-wrapper">
        <Link
          to={"/notre-histoire"}
          className={
            props.page === "histoire"
              ? "switcher-item switcher-item-selected"
              : "switcher-item"
          }
        >
          Notre histoire
        </Link>
        <Link
          to={"/qui-sommes-nous"}
          className={
            props.page === "qui-sommes-nous"
              ? "switcher-item switcher-item-selected"
              : "switcher-item"
          }
        >
          Qui sommes-nous ?
        </Link>
        <Link
          to={"/nos-valeurs"}
          className={
            props.page === "valeurs"
              ? "switcher-item switcher-item-selected"
              : "switcher-item"
          }
        >
          Nos valeurs
        </Link>
      </div>
    </div>
  );
}
