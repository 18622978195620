import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/actions";
import getWindowDimensions from "../helpers/windowDimensions";
import GetCookies from "../helpers/cookies";
import InexploreLogoWhite from "../../assets/images/svg/inexplore-logo-white.svg";
import { ReactComponent as UserIcon } from "../../assets/images/svg/user.svg";
import { ReactComponent as EarthIcon } from "../../assets/images/svg/earth.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/svg/chevron_down.svg";

export default function Header(props) {
  const [datas, setDatas] = useState(null);
  const windowDimensions = getWindowDimensions();
  const userCookies = GetCookies();

  const dispatch = useDispatch();

  useEffect(() => {
    async function getPeople() {
      const response = await dispatch(actions.getUniverse());
      setDatas(response);
    }
    getPeople();
  }, [dispatch]);

  if (windowDimensions.width >= 521) {
    return (
      <header>
        <a href="https://www.inexplore.com/" target={"_blank"} className="inexplore-logo">
          <img src={InexploreLogoWhite} alt="Inexploré" />
        </a>
        <div className="menu-wrapper">
          <div className="universe-container">
            <div className="universe-button" target="_blank" rel="noreferrer">
              <EarthIcon className="universe-icon" />
              <p>Nos univers</p>
              <ChevronDownIcon className="chevron-icon" />
            </div>
            <div className="universe-popin">
              <div className="universe-popin-container">
                {datas !== null && (
                  <ul>
                    {datas.univers.map(function (item, i) {
                      return (
                        <li key={i}>
                          <a
                            style={{ backgroundColor: `#${item.background}` }}
                            target="_blank"
                            rel="noreferrer"
                            href={item.lien}
                          >
                            <img id={i} alt={item.titre} src={item.logo}></img>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="header-icons">
            {userCookies !== undefined ? (
              <a href="https://my.inexplore.com/" target={"_blank"}>
                <div className="user-logged">
                  <span>{userCookies.prenom[0] + userCookies.nom[0]}</span>
                </div>
              </a>
            ) : (
              <a
                href={`https://login.inexplore.com?referrer=${window.location.href}`}
              >
                <UserIcon className="icon" />
              </a>
            )}
          </div>
        </div>
      </header>
    );
  } else {
    return (
      <header>
        <div className="menu-wrapper">
          <div className="universe-container">
            <div
              className="universe-button-mobile"
              target="_blank"
              rel="noreferrer"
            >
              <EarthIcon className="universe-icon" />
            </div>
            <div className="universe-popin">
              <div className="universe-popin-container">
                {datas !== null && (
                  <ul>
                    {datas.univers.map(function (item, i) {
                      return (
                        <li key={i}>
                          <a
                            style={{ backgroundColor: `#${item.background}` }}
                            target="_blank"
                            rel="noreferrer"
                            href={item.lien}
                          >
                            <img id={i} alt={item.titre} src={item.logo}></img>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <Link to={"/"} className="inexplore-logo">
          <img src={InexploreLogoWhite} alt="Inexploré" />
        </Link>
        <div className="header-icons">
          {userCookies !== undefined ? (
            <a href="https://my.inexplore.com/" target={"_blank"}>
              <div className="user-logged">
                <span>{userCookies.prenom[0] + userCookies.nom[0]}</span>
              </div>
            </a>
          ) : (
            <a
              href={`https://login.inexplore.com?referrer=${window.location.href}`}
            >
              <UserIcon className="icon" />
            </a>
          )}
        </div>
      </header>
    );
  }
}
