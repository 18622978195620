import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

/* import pages */
import NotreHistoire from "../pages/notre-histoire";
import NosValeurs from "../pages/nos-valeurs";
import QuiSommesNous from "../pages/qui-sommes-nous";
// const Bienvenue = lazy(() => import("../pages/bienvenue"));
import Bienvenue from "../pages/bienvenue";

export default function Navigation(props) {
	return (
		<Routes>
			<Route path="/notre-histoire" element={<NotreHistoire />} />
			<Route path="/nos-valeurs" element={<NosValeurs />} />
			<Route path="/qui-sommes-nous" element={<QuiSommesNous />} />
			<Route path="/bienvenue" element={<Bienvenue />} />
			<Route path="*" element={<Navigate to="/notre-histoire" />} />
		</Routes>
	);
}
