import React from "react";
import { Helmet } from "react-helmet-async";

export default function SEOMixins(props) {
	return (
		<Helmet>
			{/* Standard metadata tags */}
			<title>{props.title}</title>
			<meta name="description" content={props.description} />
			{/* End standard metadata tags */}
			{/* Facebook tags */}
			<meta property="og:type" content="website" />
			<meta property="og:title" content={props.title} />
			<meta property="og:description" content={props.description} />
			{/* End Facebook tags */}
			{/* Twitter tags */}
			<meta name="twitter:creator" content="INREES" />
			<meta name="twitter:card" content="website" />
			<meta name="twitter:title" content={props.title} />
			<meta name="twitter:description" content={props.description} />
			{/* End Twitter tags */}
		</Helmet>
	);
}
