import { configureStore } from "@reduxjs/toolkit";
import footerReducer from "./reducers/footer";

export const store = configureStore({
  reducer: {
    footerReducer: footerReducer,
  },
});

export default store;
