import React from "react";
import headerTopBackground from "../../assets/images/bienvenue/header-top-background.png";
import SEOMixins from "../mixins/SEOMixins";

const BienvenueHeader = () => {
	return (
		<div className="main full-width header-background">
			<div className={"content-wrapper"}>
				<img
					style={{ height: "100%" }}
					src={headerTopBackground}
					alt="Une création originale Inexploré TV"
					className={"background-image"}
				/>
				<div className={"gradiant-background"} />
			</div>
			<SEOMixins title="Inexploré - Bienvenue" description="Découvrez les valeurs qui orientent notre démarche" />
			<div className={"title-container"}>
				<h1>Devenez un explorateur des mystères du monde, en quête d’harmonie</h1>
				<h5>
					Plus qu’un média, Inexploré est votre label de confiance sur le chemin de la conscience, de
					l’invisible et de l’éveil. On vous embarque avec nous ?
				</h5>
			</div>
		</div>
	);
};

export default BienvenueHeader;
