import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import { Errors } from "../elements/messages";

const BienvenueConnaissance = () => {
	const [email, setEmail] = React.useState("");
	const [error, setError] = useState({
		status: false,
		message: "",
	});
	const handleChangeEmail = (value) => {
		setError({ status: false, message: "" });
		setEmail(value);
	};

	const onNewsletterFormSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (email === "") {
			setError({
				status: true,
				message: "Veuillez renseigner votre adresse email",
			});
		} else {
			const isEmailValid = EmailValidator.validate(email);
			if (!isEmailValid) {
				setError({
					status: true,
					message: "Veuillez renseigner une adresse email valide",
				});
			} else {
				const redirectUrl = `https://login.inexplore.com/newsletter?email=${email}&referrer=https://www.inexplore.com`;
				window.open(redirectUrl);
				setEmail("");
			}
		}
	};

	return (
		<div className={"bienvenue-connaissance slider"}>
			<section>
				<div className={"background-overflow"} />
				<div className={"wrapper"}>
					<h3>On fait connaissance ?</h3>
					<h5>Découvrez tous nos secrets et recevez des contenus offerts :</h5>
					<div className={"email-section"}>
						<div className={"input-container"}>
							<input
								type="email"
								placeholder="Votre adresse email"
								value={email}
								onChange={(e) => handleChangeEmail(e.target.value)}
							/>
							{error.status && (
								<Errors
									className="error-message"
									backgroundColor={"transparent"}
									textColor={"#e94b32"}
									message={error.message}
								/>
							)}
						</div>
						<button onClick={onNewsletterFormSubmit}>J'embarque !</button>
					</div>
					<p>Ne manquez pas l’appel de l’aventure…</p>
				</div>
			</section>
		</div>
	);
};

export default BienvenueConnaissance;
