import React from "react";
import BienvenueThemes from "../components/bienvenue/bienvenueThemes";
import BienvenueIntervenants from "../components/bienvenue/bienvenueIntervenants";
import BienvenueConnaissance from "../components/bienvenue/bienvenueConnaissance";
import BienvenueHeader from "../components/bienvenue/bienvenueHeader";
import BienvenueVoyage from "../components/bienvenue/bienvenueVoyage";
import BienvenueDiscoverInexplore from "../components/bienvenue/bienvenueDiscoverInexplore";

const Bienvenue = () => {
	return (
		<div className={"bienvenue"}>
			<BienvenueHeader />
			<BienvenueVoyage />
			<BienvenueDiscoverInexplore />
			<BienvenueThemes />
			<BienvenueIntervenants />
			<BienvenueConnaissance />
		</div>
	);
};

export default Bienvenue;
