import axiosInstance from "../../mixins/axios";

export const SET_FOOTER = "SET_FOOTER";

export const getFooter = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("/footer/");
      dispatch({ type: SET_FOOTER, payload: response.data.footer });
    } catch (error) {
      return error.response;
    }
  };
};

export const getPeople = () => {
  return async () => {
    try {
      const response = await axiosInstance.get("/connect/equipes/");
      return response.data.infos;
    } catch (error) {
      return error.response;
    }
  };
};

export const sendMessage = (datas) => {
  return async () => {
    try {
      const response = await axiosInstance.post("mail/send", {
        email: datas.closeUserEmail,
        sujet: `Inexploré - ${datas.user} vous partage l'histoire d'Inexploré`,
        message: `Bonjour ${datas.closeUser}, <br/><br/> Votre proche ${
          datas.user
        } souhaite vous partager l'histoire d'<a style={text-decoration:underline} href='https://a-propos.inexplore.com'>Inexploré</a>. Retrouvez ci-après son message : <br/><br/> <p style={font-style:italic}> ${datas.message.replace(
          /\r?\n/g,
          "<br />"
        )}<p> <br/> <br/> A très bientôt<br/> <br/> l'Equipe d'<a style={text-decoration:underline} href='https://a-propos.inexplore.com'>Inexploré</a>.`,
      });
      console.log(response);
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

export const getUniverse = () => {
  return async () => {
    try {
      const response = await axiosInstance.get("/connect/univers/");
      return response.data;
    } catch (error) {
      return error.response;
    }
  };
};
