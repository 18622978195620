import React, { useState } from "react";
import StarsBackground from "../../assets/images/svg/bienvenue/etoiles-background.svg";
import SVGCurvedLine from "../../assets/images/svg/bienvenue-curved-line.svg";
import { ReactComponent as Enveloppe } from "../../assets/images/svg/bienvenue/enveloppe.svg";
import { ReactComponent as Cadeau } from "../../assets/images/svg/bienvenue/cadeau.svg";
import { ReactComponent as Boussole } from "../../assets/images/svg/bienvenue/boussole.svg";
import { Errors } from "../elements/messages";
import * as EmailValidator from "email-validator";

const BienvenueVoyage = () => {
	const [email, setEmail] = React.useState("");
	const [error, setError] = useState({
		status: false,
		message: "",
	});
	const handleChangeEmail = (value) => {
		setError({ status: false, message: "" });
		setEmail(value);
	};

	const onNewsletterFormSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (email === "") {
			setError({
				status: true,
				message: "Veuillez renseigner votre adresse email",
			});
		} else {
			const isEmailValid = EmailValidator.validate(email);
			if (!isEmailValid) {
				setError({
					status: true,
					message: "Veuillez renseigner une adresse email valide",
				});
			} else {
				const redirectUrl = `https://login.inexplore.com/newsletter?email=${email}&referrer=https://www.inexplore.com`;
				window.open(redirectUrl);
				setEmail("");
			}
		}
	};

	return (
		<div className={"premier-voyage"}>
			<img
				src={StarsBackground}
				style={{ width: "100%", position: "absolute", top: 120, zIndex: 0, pointerEvents: "none" }}
				alt="website logo"
			/>
			<section className={"section-wrapper"}>
				<div className={"info-container"}>
					<h3>Un premier voyage 100% gratuit</h3>
					<p>Inscrivez-vous…</p>
					<div className={"email-section"}>
						<div className={"input-container"}>
							<input
								type="email"
								placeholder="Votre adresse email"
								value={email}
								onChange={(e) => handleChangeEmail(e.target.value)}
							/>
							{error.status && (
								<Errors
									className="error-message"
									backgroundColor={"transparent"}
									textColor={"#e94b32"}
									message={error.message}
								/>
							)}
						</div>

						<button onClick={onNewsletterFormSubmit}>J'embarque !</button>
					</div>
					<p>Et bénéficiez de…</p>
				</div>
				<div className={"cadeau"}>
					<img
						src={SVGCurvedLine}
						style={{ width: "100%", position: "absolute", top: 25, zIndex: 0 }}
						alt="website logo"
					/>
					<div className={"cadeau-content-wrapper"}>
						<div className={"cadeau-content"}>
							<div className={"icon-background"}>
								<Enveloppe />
							</div>
							<p className={"cadeau-description"}>
								Votre kit de bienvenue pour bien démarrer votre aventure
							</p>
						</div>
						<div className={"cadeau-content"}>
							<div className={"icon-background"}>
								<Cadeau />
							</div>
							<p className={"cadeau-description"}>
								Vos premiers podcasts, documentaires et articles offerts
							</p>
						</div>
						<div className={"cadeau-content"}>
							<div className={"icon-background"}>
								<Boussole />
							</div>
							<p className={"cadeau-description"}>
								Des repères pour explorer vos thématiques préférées
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default BienvenueVoyage;
