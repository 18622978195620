import React from "react";
import SEOMixins from "../components/mixins/SEOMixins";
import PageSwitcher from "../components/elements/pageSwitcher";
import { ReactComponent as CertifieIcon } from "../assets/images/svg/Certifie.svg";
import { ReactComponent as NatureIcon } from "../assets/images/svg/nature.svg";
import { ReactComponent as PerceptionIcon } from "../assets/images/svg/perception.svg";
import { ReactComponent as UsersIcon } from "../assets/images/svg/users.svg";
import { ReactComponent as MessageIcon } from "../assets/images/svg/message.svg";

export default function NosValeurs(props) {
  return (
    <div className="main full-width valeurs-color-background">
      <SEOMixins
        title="Inexploré - Nos valeurs"
        description="Découvrez les valeurs qui orientent notre démarche"
      />

      <div className="background-header valeurs-header">
        <PageSwitcher page={"valeurs"} />
        <h1>Des valeurs au service de notre démarche</h1>
        <div className="shape" id="shape-valeurs">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 293"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient
                x1="0%"
                y1="100%"
                x2="100%"
                y2="100%"
                id="linearGradient"
              >
                <stop stopColor="#1D4C7E" offset="0%"></stop>
                <stop stopColor="#1D4C7E" offset="30%"></stop>
                <stop stopColor="#99D0C1" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(0.000000, -422.000000)" fill="url(#linearGradient)">
                <g transform="translate(-4.000000, 422.878607)">
                  <path d="M4,178.678814 C59.7824497,150.973867 137.115783,131.531074 236,120.350436 C384.326325,103.579479 783.984975,118.219324 916.670202,107.147735 C1099.17766,91.9188537 1274.95426,56.202942 1444,0 L1444,292.121393 L4,292.121393 L4,178.678814 Z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="container valeurs-container">
        <section className="section-wrapper">
          <p>
            L’INREES, qui édite Inexploré, est une entreprise qui prône le{" "}
            <span>respect du vivant et de l’être humain</span> et qui s’engage
            en faveur de l’évolution des consciences.
            <br />
            <br />
            L’écoute, l’ouverture d ’esprit et la{" "}
            <span>recherche perpétuelle de la qualité</span> sont les valeurs
            principales que nous avons à cœur d’incarner dans notre quotidien.{" "}
            <br />
            <br />
            <span>La bienveillance</span> est, quant à elle, notre inspiration,
            tant pour nos clients, nos partenaires que pour nos collaborateurs.
            <br />
            <br />
            <br />
            Figure de ces fondements déontologiques, la démarche de l’INREES et
            d’Inexploré se veut :
          </p>
          <div className="floral-background"></div>
        </section>
        <section className="section-wrapper">
          <ul>
            <li>
              <div className="circle" style={{ backgroundColor: "#EF7A91" }}>
                <PerceptionIcon />
              </div>
              <div className="text-container">
                <h2>Éthique</h2>
                <p>
                  Nous vous garantissons une expérience sans publicité invasive.
                  La sécurité et la confidentialité de vos données personnelles
                  sont nos priorités.
                </p>
              </div>
              <img />
            </li>
            <li>
              <div className="circle" style={{ backgroundColor: "#EABF46" }}>
                <CertifieIcon />
              </div>
              <div className="text-container">
                <h2>Label de confiance</h2>
                <p>
                  Pour que les informations que nous partageons participent à
                  l’éveil du monde, nos investigations journalistiques sont
                  soumises à un regard critique très exigeant.
                </p>
              </div>
              <img />
            </li>
            <li>
              <div className="circle" style={{ backgroundColor: "#99D0C1" }}>
                <NatureIcon />
              </div>
              <div className="text-container">
                <h2>A l'image d'un écosystème</h2>
                <p>
                  Notre planète qui est un trésor à protéger est notre source
                  d’inspiration. L’organisation de notre entreprise est ancrée
                  dans ce concept de biosphère.
                </p>
              </div>
              <img />
            </li>
            <li>
              <div className="circle" style={{ backgroundColor: "#6C3490" }}>
                <UsersIcon />
              </div>
              <div className="text-container">
                <h2>Tournée vers les abonnés</h2>
                <p>
                  Pour permettre au plus grand nombre d’accéder à nos contenus,
                  nous nous engageons à l’excellence dans notre travail ainsi
                  qu’au maintien de prix abordables et justes.
                </p>
              </div>
              <img />
            </li>
            <li>
              <div className="circle" style={{ backgroundColor: "#36A9E1" }}>
                <MessageIcon />
              </div>
              <div className="text-container">
                <h2>D'intérêt général</h2>
                <p>
                  Lorsque les expériences se veulent bouleversantes, nous
                  proposons à ceux qui les vivent une écoute et des conseils
                  gratuits prodigués par notre réseau de professionnels de
                  santé.
                </p>
              </div>
              <img />
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
