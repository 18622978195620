import { SET_FOOTER } from "../actions/actions";

const initialState = {
  footer: null,
};

function footerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FOOTER:
      return { ...state, footer: action.payload };
    default:
      return state;
  }
}

export default footerReducer;
