import React from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import SEOMixins from "../components/mixins/SEOMixins";
import PageSwitcher from "../components/elements/pageSwitcher";
import ShareHistoryForm from "../components/elements/forms/shareHistoryForm";

export default function NotreHistoire(props) {
  const footer = useSelector((state) => state.footerReducer.footer);

  return (
    <div className="main full-width">
      <SEOMixins
        title="Inexploré - Notre histoire"
        description="Une histoire tournée vers l'extraordinaire"
      />

      <div className="background-header histoire-header">
        <PageSwitcher page={"histoire"} />
        <h1>Une histoire tournée vers l'extraordinaire</h1>
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 293"
            preserveAspectRatio="none"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(0.000000, -422.000000)" fill="#99D0C1">
                <g transform="translate(-4.000000, 422.878607)">
                  <path d="M4,178.678814 C59.7824497,150.973867 137.115783,131.531074 236,120.350436 C384.326325,103.579479 783.984975,118.219324 916.670202,107.147735 C1099.17766,91.9188537 1274.95426,56.202942 1444,0 L1444,292.121393 L4,292.121393 L4,178.678814 Z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="main-container">
        <div className="container histoire-container">
          <section className="histoire-mystere section-wrapper">
            <div className="text-container">
              <h2>Du mystère à la transformation</h2>
              <p>
                Tout autour de nous, de nombreux évènements indiquent
                discrètement que la réalité est bien plus vaste que nous le
                pensons. Et si nous ouvrions notre esprit aux mondes subtils ?{" "}
                <br />
                <br />
                C’est avec cette perception du monde que l’INREES, éditeur
                d’Inexploré, invite sa communauté à se relier aux dimensions
                extraordinaires de la vie, afin que chacun puisse révéler son
                potentiel intérieur et transformer sa relation à la nature, aux
                autres, au cosmos, à soi. Un appel sans équivoque pour
                encourager le plus grand nombre à appliquer ce précepte
                ancestral : « Connais-toi toi-même, et tu connaîtras l’Univers
                et les Dieux ».
              </p>
            </div>
            <div className="histoire-image">
              <img
                alt="Du mystère à la transformation"
                className="mystere-image"
              ></img>
            </div>
            <div className="floral-background-histoire-mystere" />
          </section>
          <section className="histoire-aventure section-wrapper">
            <div className="histoire-image">
              <img
                alt="Une aventure de pionniers"
                className="aventure-image"
              ></img>
            </div>

            <div className="text-container">
              <h2>Une aventure de pionniers</h2>
              <p>
                Devenir un explorateur de la conscience requiert du courage.
                Lorsque les questions existentielles se présentent, deux choix
                s’offrent à nous : soit nous les ignorons, soit nous nous
                aventurons hors des sentiers battus, sur les chemins du mystère
                !
                <br /> <br />À l’adolescence, Sébastien Lilli traverse le chaos.
                Une nuit noire de l’âme le conduit à vivre des expériences
                extraordinaires. C’est le début d’une quête de sens qu'il n'a
                jamais interrompue.
                <br />
                <br /> À seulement 20 ans, il rejoint Stéphane Allix, ancien
                reporter de guerre qui, depuis le décès son frère, se questionne
                sur la vie après la mort. Ensemble, ils créent l’INREES et
                inventent au fil de leurs inspirations Inexploré, un espace
                d’investigation inédit et sérieux, devenu, depuis lors, un
                véritable label de confiance.
              </p>
            </div>
          </section>
          <section className="histoire-inexplore section-wrapper">
            <div className="text-container">
              <h2>Explorer l'inexploré</h2>
              <p>
                En plus d’être un média à 360°, Inexploré est un phare et un
                lieu de ressources. Une boussole qui nous accompagne au
                quotidien à la croisée des mondes, naviguant entre science et
                spiritualité, entre visible et invisible…
                <br />
                <br />
                Inexploré digital propose ainsi chaque semaine des enquêtes, des
                actualités, des podcasts et un accès illimité à ses archives.
                <br />
                <br />
                Inexploré magazine, est une revue papier trimestrielle unique, à
                collectionner chaque solstice et équinoxe.
                <br />
                <br />
                Inexploré TV, plateforme de vidéos, prolonge sensoriellement nos
                expériences avec ses documentaires, séries et créations
                singulières et inattendues.
              </p>
            </div>
            <div className="histoire-image">
              <img alt="Explorer l'inexploré" className="inexplore-image"></img>
            </div>
            <div className="floral-background-histoire-inexplore" />
          </section>
        </div>
      </div>
      <section className="video-container">
        <h2>Notre histoire en vidéo</h2>
        <div className="player-wrapper">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=vaTHpvAw2C8"
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient
                x1="0%"
                y1="100%"
                x2="100%"
                y2="90%"
                id="linearGradient"
              >
                <stop stopColor="#21639e" offset="0%"></stop>
                <stop stopColor="#6c3490" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              d="M-27.09,145.56 C144.47,87.34 213.88,168.26 509.59,79.45 L502.82,153.46 L-0.56,156.42 Z"
              fill="url(#linearGradient)"
            ></path>
          </svg>
        </div>
      </section>

      <section className="thank-container">
        <div className="text-container">
          <h2>Merci !</h2>
          <p>
            Inexploré est un média libre et indépendant qui existe grâce au
            soutien de toute une communauté : abonnés, collaborateurs,
            partenaires. Tous ensemble, nous partageons cette même vision que
            notre engagement, aussi infime soit-il, dans l'aventure de
            l'extraordinaire est fondamental et éclairant. Inexploré prend soin
            ici de redistribuer ces soutiens, ces énergies porteuses et ces
            chaleureux remerciements !
          </p>
          {/*   <div className="floral-background"></div> */}
        </div>
      </section>
      {footer !== null && (
        <div className="share">
          <h2>Rejoignez-nous sur nos réseaux sociaux</h2>
          <h3>Partagez et abonnez-vous</h3>

          <div className="socials-networks">
            <ul>
              {footer.reseaux.items.map((item) => {
                return (
                  <li key={item.titre}>
                    <a href={item.lien} target="_blank" rel="noreferrer">
                      <img
                        src={item.picto}
                        className="icon"
                        alt={item.titre}
                      ></img>
                      {item.titre}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      <div className="share-form">
        <h2>Partagez notre histoire à un proche</h2>
        <ShareHistoryForm />
      </div>
    </div>
  );
}
