import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

export default function GetCookies() {
  const [userCookie, setUserCookie] = useState();

  function getUserCookies() {
    const cookies = new Cookies();
    const inexploreUser = cookies.get("INEXPLORE_USER");
    setUserCookie(inexploreUser);
  }

  useEffect(() => {
    function checkUserCookies() {
      getUserCookies();
    }
    checkUserCookies();
  }, []);

  return userCookie;
}
