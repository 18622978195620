import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as AmeDuMonde } from "../../assets/images/svg/bienvenue/themes/ame-monde-icon.svg";
import { ReactComponent as ArtVivre } from "../../assets/images/svg/bienvenue/themes/art-vivre-icon.svg";
import { ReactComponent as AuDela } from "../../assets/images/svg/bienvenue/themes/au-dela-icon.svg";
import { ReactComponent as Cosmos } from "../../assets/images/svg/bienvenue/themes/cosmos-icon.svg";
import { ReactComponent as FinVie } from "../../assets/images/svg/bienvenue/themes/fin-vie-icon.svg";
import { ReactComponent as Inspirations } from "../../assets/images/svg/bienvenue/themes/inspirations-icon.svg";
import { ReactComponent as LieuxMysterieux } from "../../assets/images/svg/bienvenue/themes/lieux-mysterieux-icon.svg";
import { ReactComponent as NatureIcon } from "../../assets/images/svg/bienvenue/themes/nature-icon.svg";
import { ReactComponent as Perception } from "../../assets/images/svg/bienvenue/themes/perception-icon.svg";
import { ReactComponent as Sante } from "../../assets/images/svg/bienvenue/themes/sante-corps-esprit-icon.svg";
import { ReactComponent as Savoirs } from "../../assets/images/svg/bienvenue/themes/savoirs-ancestraux-icon.svg";
import { ReactComponent as Science } from "../../assets/images/svg/bienvenue/themes/science-icon.svg";
import { ReactComponent as RightArrow } from "../../assets/images/svg/bienvenue/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/images/svg/bienvenue/left-arrow.svg";

const THEMES = [
	{ title: "Au-delà", description: "Et si la mort n’existait pas ?", icon: AuDela },
	{ title: "Perceptions", description: "S'ouvrir à l'intuition et à l'extrasensoriel", icon: Perception },
	{ title: "Santé", description: "Corps-esprit, une vision holistique", icon: Sante },
	{ title: "Art de vivre", description: "Être mieux avec soi-même", icon: ArtVivre },
	{ title: "Savoirs ancestraux", description: "Outils millénaires pour se réenchanter", icon: Savoirs },
	{ title: "Âme du monde", description: "Aller plus loin que nos rêves…", icon: AmeDuMonde },
	{ title: "Sciences", description: "Ancrer nos connaissances", icon: Science },
	{ title: "Cosmos", description: "Enquêter sur la vie extraterrestre", icon: Cosmos },
	{ title: "Lieux mystérieux", description: "Sentir les sanctuaires de la Terre", icon: LieuxMysterieux },
	{ title: "Nature", description: "Entrer en symbiose avec les règnes", icon: NatureIcon },
	{ title: "Inspirations", description: "Co-créer le monde de demain", icon: Inspirations },
	{ title: "Fin de vie", description: "Accepter nos éternelles renaissances", icon: FinVie },
];

const responsive = {
	xxxl: {
		breakpoint: { max: 3000, min: 1100 },
		items: 6,
		slidesToSlide: 3, // optional, default to 1.
	},
	xxl: {
		breakpoint: { max: 1100, min: 980 },
		items: 5,
		slidesToSlide: 2, // optional, default to 1.
	},
	xl: {
		breakpoint: { max: 980, min: 790 },
		items: 4,
		slidesToSlide: 1, // optional, default to 1.
	},
	l: {
		breakpoint: { max: 790, min: 594 },
		items: 3,
		slidesToSlide: 1, // optional, default to 1.
	},
	m: {
		breakpoint: { max: 594, min: 0 },
		items: 2,
		slidesToSlide: 1, // optional, default to 1.
	},
	s: {
		breakpoint: { max: 410, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

//console.log(THEMES);

const CustomLeftArrow = ({ onClick }) => (
	<div onClick={onClick} className={"custom-arrow custom-left-arrow"}>
		<LeftArrow />
	</div>
);
const CustomRightArrow = ({ onClick }) => (
	<div onClick={onClick} className={"custom-arrow custom-right-arrow"}>
		<RightArrow />
	</div>
);

const BienvenueThemes = () => {
	return (
		<div className={"bienvenue-themes slider"}>
			<section>
				<div className={"background-overflow"} />
				<h3>Des thèmes pour s’éveiller</h3>
				<div className={"swiper-wrapper"}>
					<Carousel
						swipeable={true}
						draggable={true}
						showDots={true}
						responsive={responsive}
						ssr={false}
						infinite={false}
						autoPlay={false}
						autoPlaySpeed={1000}
						keyBoardControl={true}
						transitionDuration={500}
						removeArrowOnDeviceType={["tablet", "mobile"]}
						customLeftArrow={<CustomLeftArrow />}
						customRightArrow={<CustomRightArrow />}
					>
						{THEMES?.map((theme) => {
							const SVG = theme.icon;
							return (
								<div className="swiper-slide">
									<div className="block-icone-slide">
										<SVG />
									</div>
									<h4>{theme.title}</h4>
									<p className="swiper-description">{theme.description}</p>
								</div>
							);
						})}
					</Carousel>
				</div>
			</section>
		</div>
	);
};

export default BienvenueThemes;
