import React, { useEffect, useState } from "react";
import * as actions from "../store/actions/actions";
import { useDispatch } from "react-redux";
import SEOMixins from "../components/mixins/SEOMixins";
import PageSwitcher from "../components/elements/pageSwitcher";

export default function QuiSommesNous(props) {
  const [datas, setDatas] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getPeople() {
      const response = await dispatch(actions.getPeople());
      setDatas(response);
    }
    getPeople();
  }, [dispatch]);

  return (
    <div className="main full-width">
      <SEOMixins
        title="Inexploré - Qui-sommes-nous ?"
        description="Une équipe pour vous faire découvrir l’inexploré"
      />
      <div className="background-header qui-sommes-nous-header">
        <PageSwitcher page={"qui-sommes-nous"} />
        <h1>Des acteurs inspirés et inspirants</h1>
        <div className="shape" id="shape-qui-sommes-nous">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 293"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient
                x1="0%"
                y1="100%"
                x2="100%"
                y2="90%"
                id="linearGradient"
              >
                <stop stopColor="#21639e" offset="0%"></stop>
                <stop stopColor="#6c3490" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(0.000000, -422.000000)"
                fill="url(#linearGradient)"
              >
                <g transform="translate(-4.000000, 422.878607)">
                  <path d="M4,178.678814 C59.7824497,150.973867 137.115783,131.531074 236,120.350436 C384.326325,103.579479 783.984975,118.219324 916.670202,107.147735 C1099.17766,91.9188537 1274.95426,56.202942 1444,0 L1444,292.121393 L4,292.121393 L4,178.678814 Z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <section className="co-founder-container container-qui-somme-nous">
        <div className="co-founder-wrapper section-wrapper">
          {datas !== null && (
            <div className="text-container">
              <h3>
                {datas.president.prenom} {datas.president.nom.toUpperCase()}
              </h3>
              <h4>{datas.president.profession}</h4>
              <p>{datas.president.description}</p>
              <div className="buttons-container">
                <a
                  href={datas.president.lien}
                  className="btn-action"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  En savoir plus
                </a>
                <a
                  href={datas.president.facebook}
                  className="btn-social-profil"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img id="facebook" alt="Facebook" />
                </a>
                <a
                  href={datas.president.twitter}
                  className="btn-social-profil"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img id="twitter" alt="Twitter" />
                </a>
                <a
                  href={datas.president.instagram}
                  className="btn-social-profil"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img id="instagram" alt="instagram" />
                </a>
              </div>
            </div>
          )}
          {datas !== null && (
            <div className="co-founder-image image-container">
              <div className="image round image-wrapper">
                <img
                  alt={`${datas.president.prenom} ${datas.president.nom}`}
                  src={datas.president.photo}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="associates-container container-qui-somme-nous">
        {datas !== null && (
          <div className={datas.associes.length > 1 && "section-wrapper"}>
            {datas.associes.map(function (item, i) {
              if (datas.associes.length > 1) {
                return (
                  <div className="section-wrapper-associates section-wrapper">
                    <div className="multiple-associates-image image-container">
                      <div className="image round image-wrapper">
                        <img
                          alt={`${item.prenom} ${item.nom}`}
                          src={item.photo}
                        />
                      </div>
                    </div>
                    <div className="text-container">
                      <span>Co-fondateur historique</span>
                      <h3>
                        {item.prenom} {item.nom.toUpperCase()}
                      </h3>
                      <p className="small-description">{item.description}</p>
                      <a
                        href={item.lien}
                        className="btn-action"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        En savoir plus
                      </a>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="section-wrapper"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="single-associates-image image-container">
                      <div className="image round image-wrapper">
                        <img
                          alt={`${item.prenom} ${item.nom}`}
                          src={item.photo}
                        />
                      </div>
                    </div>
                    <div className="text-container">
                      <span>Co-fondateur historique</span>
                      <h3>
                        {item.prenom} {item.nom.toUpperCase()}
                      </h3>
                      <h4>{item.profession}</h4>
                      <p className="long-description">{item.description}</p>
                      <a
                        href={item.lien}
                        className="btn-action"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        En savoir plus
                      </a>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
          >
            <path
              d="M-4.51,127.80 C168.17,179.12 212.75,108.06 522.57,149.51 L503.38,157.41 L-3.95,152.47 Z"
              fill="#191b32"
            ></path>
          </svg>
        </div>
      </section>
      <section className="team-container container-qui-somme-nous">
        <div className="team-wrapper">
          <h2>L'équipe</h2>
          {datas !== null && (
            <div className="team-grid">
              {datas.equipe.map(function (item, i) {
                return (
                  <a
                    href={item.lien ? item.lien : null}
                    className="grid-element"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <div className="team-image image-container">
                      <div className="image round image-wrapper">
                        <img
                          alt={`${item.prenom} ${item.nom}`}
                          src={item.photo}
                        />
                      </div>
                    </div>
                    <h4>
                      {item.prenom} {item.nom}
                    </h4>
                    <span>{item.profession}</span>
                  </a>
                );
              })}
            </div>
          )}
          <div className="floral-background" />
        </div>
      </section>
      <section className="journalists-container container-qui-somme-nous">
        <div className="journalists-wrapper">
          <h2>Les journalistes et auteurs</h2>
          <p>
          Créateurs de nos articles, podcasts et documentaires
          </p>
          {datas !== null && (
            <div className="journalists-grid">
              {datas.journalistes.map(function (item, i) {
                return (
                  <a
                    href={item.lien ? item.lien : null}
                    className="grid-element"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <div className="journalist-image image-container">
                      <div className="image round image-wrapper">
                        <img
                          alt={`${item.prenom} ${item.nom}`}
                          src={item.photo}
                        />
                      </div>
                    </div>
                    <h4>
                      {item.prenom} {item.nom}
                    </h4>
                    <span>{item.profession}</span>
                  </a>
                );
              })}
            </div>
          )}
          <div className="journalists-shape" />
        </div>
        <div className="wave">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
          >
            <path
              d="M-4.51,127.80 C168.17,179.12 212.75,108.06 522.57,149.51 L503.38,157.41 L-3.95,152.47 Z"
              fill="#99d0c1"
            ></path>
          </svg>
        </div>
      </section>
      <section className="network-container container-qui-somme-nous">
        <div className="network-wrapper">
          <h2>Notre réseau santé</h2>
          <p>
          Les professionnels de santé impliqués chaque semaine dans notre démarche d'intérêt général
          </p>
          {datas !== null && (
            <div className="network-grid">
              {datas.reseau.map(function (item, i) {
                return (
                  <a
                    href={item.lien ? item.lien : null}
                    className="grid-element"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <div className="network-image image-container">
                      <div className="image image-wrapper">
                        <img
                          alt={`${item.prenom} ${item.nom}`}
                          src={item.photo}
                        />
                      </div>
                    </div>
                    <div className="text-container">
                      <h4>
                        {item.prenom} {item.nom}
                      </h4>
                      <span>{item.profession}</span>
                    </div>
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </section>
      <section className="supports-container container-qui-somme-nous">
        <div className="supports-wrapper">
          <h2>ILS NOUS SOUTIENNENT</h2>
          {datas !== null && (
            <div className="supports-grid">
              {datas.soutiens.map(function (item, i) {
                return (
                  <a
                    href={item.lien ? item.lien : null}
                    className="grid-element"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <div className="supports-image image-container">
                      <div className="image round image-wrapper">
                        <img
                          alt={`${item.prenom} ${item.nom}`}
                          src={item.photo}
                        />
                      </div>
                    </div>
                    <div className="text-container">
                      <h4>
                        {item.prenom} {item.nom}
                      </h4>
                      <span>{item.profession}</span>
                    </div>
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
