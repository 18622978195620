import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as AmeDuMonde } from "../../assets/images/svg/bienvenue/themes/ame-monde-icon.svg";
import { ReactComponent as ArtVivre } from "../../assets/images/svg/bienvenue/themes/art-vivre-icon.svg";
import { ReactComponent as AuDela } from "../../assets/images/svg/bienvenue/themes/au-dela-icon.svg";
import { ReactComponent as Cosmos } from "../../assets/images/svg/bienvenue/themes/cosmos-icon.svg";
import { ReactComponent as FinVie } from "../../assets/images/svg/bienvenue/themes/fin-vie-icon.svg";
import { ReactComponent as Inspirations } from "../../assets/images/svg/bienvenue/themes/inspirations-icon.svg";
import { ReactComponent as LieuxMysterieux } from "../../assets/images/svg/bienvenue/themes/lieux-mysterieux-icon.svg";
import { ReactComponent as NatureIcon } from "../../assets/images/svg/bienvenue/themes/nature-icon.svg";
import { ReactComponent as Perception } from "../../assets/images/svg/bienvenue/themes/perception-icon.svg";
import { ReactComponent as Sante } from "../../assets/images/svg/bienvenue/themes/sante-corps-esprit-icon.svg";
import { ReactComponent as Savoirs } from "../../assets/images/svg/bienvenue/themes/savoirs-ancestraux-icon.svg";
import { ReactComponent as Science } from "../../assets/images/svg/bienvenue/themes/science-icon.svg";
import { ReactComponent as RightArrow } from "../../assets/images/svg/bienvenue/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/images/svg/bienvenue/left-arrow.svg";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/actions";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const responsive = {
	xxxl: {
		breakpoint: { max: 3000, min: 1100 },
		items: 6,
		slidesToSlide: 3, // optional, default to 1.
	},
	xxl: {
		breakpoint: { max: 1100, min: 900 },
		items: 6,
		slidesToSlide: 2, // optional, default to 1.
	},
	xl: {
		breakpoint: { max: 900, min: 790 },
		items: 4,
		slidesToSlide: 1, // optional, default to 1.
	},
	l: {
		breakpoint: { max: 790, min: 594 },
		items: 3,
		slidesToSlide: 1, // optional, default to 1.
	},
	m: {
		breakpoint: { max: 594, min: 0 },
		items: 2,
		slidesToSlide: 1, // optional, default to 1.
	},
	s: {
		breakpoint: { max: 410, min: 0 },
		items: 2,
		slidesToSlide: 1, // optional, default to 1.
	},
};

const CustomLeftArrow = ({ onClick }) => (
	<div onClick={onClick} className={"custom-arrow custom-left-arrow"}>
		<LeftArrow />
	</div>
);
const CustomRightArrow = ({ onClick }) => (
	<div onClick={onClick} className={"custom-arrow custom-right-arrow"}>
		<RightArrow />
	</div>
);

const BienvenueIntervenants = () => {
	const [datas, setDatas] = useState(null);

	const windowDimensions = useWindowDimensions();

	const dispatch = useDispatch();

	useEffect(() => {
		async function getPeople() {
			const response = await dispatch(actions.getPeople());
			setDatas(response?.soutiensfare);
		}
		getPeople();
	}, [dispatch]);

	return (
		<div className={"bienvenue-intervenants slider"}>
			<section>
				<h3>Des intervenants de confiance</h3>
				<div className={"swiper-wrapper"}>
					{datas?.length > 0 && (
						<Carousel
							swipeable={true}
							draggable={true}
							showDots={windowDimensions?.width > 1000}
							responsive={responsive}
							ssr={false}
							infinite={false}
							autoPlay={false}
							partialVisible={true}
							autoPlaySpeed={1000}
							keyBoardControl={true}
							transitionDuration={500}
							removeArrowOnDeviceType={["tablet", "mobile"]}
							customLeftArrow={<CustomLeftArrow />}
							customRightArrow={<CustomRightArrow />}
						>
							{datas?.map((partenaire) => {
								return (
									<div className="swiper-slide">
										<div className="block-icone-slide">
											<img src={partenaire.photo} draggable={false} />
										</div>
										<h4>{`${partenaire.prenom} ${partenaire.nom}`}</h4>
										<p className="swiper-description">{partenaire.profession}</p>
									</div>
								);
							})}
						</Carousel>
					)}
				</div>
			</section>
		</div>
	);
};

export default BienvenueIntervenants;
