import React from "react";
import discoverInexploreBackground from "../../assets/images/bienvenue/discover-inexplore-background.png";
import { ReactComponent as InexploreTitle } from "../../assets/images/svg/bienvenue/inexplore-title.svg";
import { ReactComponent as CirclePlay } from "../../assets/images/svg/bienvenue/circle-play.svg";
import { ReactComponent as Magazine } from "../../assets/images/svg/bienvenue/magazine.svg";
import { ReactComponent as Podcast } from "../../assets/images/svg/bienvenue/podcast.svg";
const BienvenueDiscoverInexplore = () => {
	return (
		<div className={"discover-inexplore"}>
			<div className={"content-wrapper"}>
				<img
					style={{ height: "100%" }}
					src={discoverInexploreBackground}
					alt="Une création originale Inexploré TV"
					className={"background-image"}
				/>
				<div className={"gradiant-background"} />
			</div>
			<section className={"section-wrapper"}>
				<InexploreTitle />
				<h5>
					Inexploré, c’est un phare dans la nuit, entre science et spiritualité, qui réunit aujourd’hui des
					centaines de milliers de lecteurs !
				</h5>
				<div className={"inexplore-content-wrapper"}>
					<div className={"content-box"}>
						<div className={"content-box-blur"} />
						<div className={"content-header"}>
							<CirclePlay />
							<div className={"content-title"}>
								<h6>Des vidéos</h6>
								<p>avec Inexploré TV&nbsp;</p>
							</div>
						</div>
						<div className={"content-description"}>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Des documentaires, séries, émissions, événements</p>
							</div>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Une plateforme de vidéos francophone unique</p>
							</div>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Une application pour smartphones, tablettes et TV</p>
							</div>
						</div>
					</div>
					<div className={"content-box"}>
						<div className={"content-box-blur"} />
						<div className={"content-header"}>
							<Magazine />
							<div className={"content-title"}>
								<h6>Des magazines</h6>
								<p>avec Inexploré magazine</p>
							</div>
						</div>
						<div className={"content-description"}>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Un trimestriel papier à collectionner chaque saison</p>
							</div>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Des enquêtes, des expertises et des conseils pratiques</p>
							</div>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>La revue de référence sur des sujets extraordinaires</p>
							</div>
						</div>
					</div>
					<div className={"content-box"}>
						<div className={"content-box-blur"} />
						<div className={"content-header"}>
							<Podcast />
							<div className={"content-title"}>
								<h6>Des podcasts</h6>
								<p>avec Inexploré digital</p>
							</div>
						</div>
						<div className={"content-description"}>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Des contenus exclusifs chaque semaine</p>
							</div>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Des articles, dossiers et tous nos magazines numériques</p>
							</div>
							<div className={"content-line"}>
								<div className={"content-dot"} />
								<p>Une application originale avec nos sélections de livres et films</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default BienvenueDiscoverInexplore;
