import React from "react";
import { ReactComponent as Envelope } from "../../../assets/images/svg/envelope.svg";

export default function TextInput(props) {
  const handleChange = (e) => {
    props.handleChangeParent(e.target.value);
  };

  if (props.type === "email") {
    return (
      <div className={props.className}>
        <div className="input-container">
          <input
            type={props.type}
            value={props.value}
            disabled={props.disabled}
            onChange={handleChange}
          />
          <label className={props.value && "filled"}>{props.label}</label>
        </div>
        {props.requiredMention ? (
          props.rightIcon ? (
            <div className="required-right-icon">
              <span>requis</span>
              <i>
                <Envelope />
              </i>
            </div>
          ) : (
            <div className="required-only">
              <span>requis</span>
            </div>
          )
        ) : (
          props.rightIcon && (
            <i>
              <Envelope />
            </i>
          )
        )}
      </div>
    );
  }

  if (props.type === "text") {
    return (
      <div className={props.className}>
        <div className="input-container">
          <input
            type={props.type}
            value={props.value}
            disabled={props.disabled}
            onChange={handleChange}
          />
          <label className={props.value && "filled"}>{props.label}</label>
        </div>
        {props.requiredMention ? (
          props.rightIcon ? (
            <div className="required-right-icon">
              <span>requis</span>
              <i>
                <Envelope />
              </i>
            </div>
          ) : (
            <div className="required-only">
              <span>requis</span>
            </div>
          )
        ) : (
          props.rightIcon && (
            <i>
              <Envelope />
            </i>
          )
        )}
      </div>
    );
  }

  if (props.type === "message") {
    return (
      <textarea
        className={props.className}
        type="text"
        placeholder={props.label}
        value={props.value}
        disabled={props.disabled}
        onChange={handleChange}
      />
    );
  }
}
