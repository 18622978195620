import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Navigation from "./router/routes";
import Header from "./components/layout/header";
import Footer from "./components/layout/Footer";

import "./assets/scss/main.scss";
import Cookies from "universal-cookie";

export default function App() {
	const queryParameters = new URLSearchParams(window.location.search);

	const setCookie = (cookieValue) => {
		const cookies = new Cookies();
		cookies.set("affiliation", cookieValue, {
			path: "/",
			domain: ".inexplore.com",
			expires: new Date(Date.now() + 2678400000),
		});
	};

	React.useEffect(() => {
		const affiliation = queryParameters.get("affiliation");
		if (affiliation) setCookie(affiliation);
	}, [queryParameters]);

	return (
		<HelmetProvider>
			<Header />
			<Navigation />
			<Footer />
		</HelmetProvider>
	);
}
