import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/actions";
import * as EmailValidator from "email-validator";
import TextInput from "./TextInput";
import { Errors, Success } from "../messages";

export default function ShareHistoryForm(props) {
  const [userFirstname, setUserFirstname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [closeUserFirstname, setCloseUserFirstname] = useState("");
  const [closeUserEmail, setCloseUserEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessageDisplayed, setSuccessMessageDisplayed] = useState(false);
  const [errors, setErrors] = useState({
    userFirstname: false,
    userEmail: { error: false, message: "" },
    closeUserFirstname: false,
    closeUserEmail: { error: false, message: "" },
    message: false,
    submit: false,
  });

  const dispatch = useDispatch();

  /* states handler */

  const handleChangeCloseUserEmail = (value) => {
    setCloseUserEmail(value);
    setErrors({ ...errors, closeUserEmail: { error: false, message: "" } });
  };

  const handleChangeCloseUserFirstname = (value) => {
    setCloseUserFirstname(value);
    setErrors({ ...errors, closeUserFirstname: false });
  };

  const handleChangeUserEmail = (value) => {
    setUserEmail(value);
    setErrors({ ...errors, userEmail: { error: false, message: "" } });
  };

  const handleChangeUserFirstname = (value) => {
    setUserFirstname(value);
    setErrors({ ...errors, userFirstname: false });
  };

  const handleChangeMessage = (value) => {
    setMessage(value);
    setErrors({ ...errors, message: false });
  };

  const displaySuccessMessage = () => {
    setSuccessMessageDisplayed(true);
    setTimeout(() => {
      setSuccessMessageDisplayed(false);
    }, 3000);
  };

  /* Submit form & validations */

  const sendMessage = async (datas) => {
    try {
      const response = await dispatch(actions.sendMessage(datas));
      if (response.status === 200) {
        displaySuccessMessage();
        setCloseUserEmail("");
        setMessage("");
        setCloseUserFirstname("");
        setUserFirstname("");
        setUserEmail("");
      } else {
        setErrors({ ...errors, submit: true });
      }
    } catch (error) {
      setErrors({ ...errors, submit: true });
    }
  };

  const submitShareMessageForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newErrors = {
      userFirstname: false,
      userEmail: {
        error: false,
        message: "",
      },
      closeUserFirstname: false,
      closeUserEmail: {
        error: false,
        message: "",
      },
      message: false,
      submit: false,
    };
    if (userFirstname.length < 1) {
      newErrors.userFirstname = true;
    }
    if (userEmail === "") {
      newErrors.userEmail.error = true;
      newErrors.userEmail.message = "Veuillez renseigner une adresse email";
    } else {
      const isEmailValid = EmailValidator.validate(userEmail);
      if (!isEmailValid) {
        newErrors.userEmail.error = true;
        newErrors.userEmail.message =
          "Veuillez renseigner une adresse email valide";
      }
    }
    if (closeUserEmail === "") {
      newErrors.closeUserEmail.error = true;
      newErrors.closeUserEmail.message =
        "Veuillez renseigner une adresse email";
    } else {
      const isEmailValid = EmailValidator.validate(closeUserEmail);
      if (!isEmailValid) {
        newErrors.closeUserEmail.error = true;
        newErrors.closeUserEmail.message =
          "Veuillez renseigner une adresse email valide";
      }
    }
    if (closeUserFirstname.length < 1) {
      newErrors.closeUserFirstname = true;
    }
    if (message.length < 1) {
      newErrors.message = true;
    }
    setErrors(newErrors);
    if (
      !newErrors.userFirstname &&
      !newErrors.closeUserFirstname &&
      !newErrors.userEmail.error &&
      !newErrors.closeUserEmail.error &&
      !newErrors.message
    ) {
      sendMessage({
        user: userFirstname,
        userEmail: userEmail,
        closeUser: closeUserFirstname,
        closeUserEmail: closeUserEmail,
        message: message,
      });
    }
  };

  return (
    <form className="share-form-container">
      <table>
        <tbody>
          <tr>
            <td>
              <span id="field-type">Votre proche</span>
            </td>
          </tr>
          <tr>
            <td>
              <TextInput
                className={
                  !errors.closeUserEmail.error
                    ? "input-floating input-border"
                    : "input-floating input-border-error"
                }
                label="Email de votre proche"
                type="email"
                value={closeUserEmail}
                disabled={false}
                rightIcon={true}
                requiredMention={true}
                handleChangeParent={handleChangeCloseUserEmail}
              ></TextInput>
              {errors.closeUserEmail.error && (
                <Errors
                  className="error-message"
                  backgroundColor={"rgba(233, 75, 50, 0.08)"}
                  textColor={"#e94b32"}
                  message={errors.closeUserEmail.message}
                />
              )}
            </td>
            <td>
              <TextInput
                className={
                  !errors.closeUserFirstname
                    ? "input-floating input-border"
                    : "input-floating input-border-error"
                }
                label="Prénom de votre proche"
                type="text"
                value={closeUserFirstname}
                disabled={false}
                rightIcon={false}
                requiredMention={true}
                handleChangeParent={handleChangeCloseUserFirstname}
              ></TextInput>
              {errors.closeUserFirstname && (
                <Errors
                  className="error-message"
                  backgroundColor={"rgba(233, 75, 50, 0.08)"}
                  textColor={"#e94b32"}
                  message={"Vous devez renseigner un prénom"}
                />
              )}
            </td>
          </tr>
          <tr>
            <td id="intro">
              <span id="field-type">Vous</span>
            </td>
          </tr>
          <tr>
            <td>
              <TextInput
                className={
                  !errors.userEmail.error
                    ? "input-floating input-border"
                    : "input-floating input-border-error"
                }
                label="Votre email"
                type="email"
                value={userEmail}
                disabled={false}
                rightIcon={true}
                requiredMention={true}
                handleChangeParent={handleChangeUserEmail}
              ></TextInput>
              {errors.userEmail.error && (
                <Errors
                  className="error-message"
                  backgroundColor={"rgba(233, 75, 50, 0.08)"}
                  textColor={"#e94b32"}
                  message={errors.userEmail.message}
                />
              )}
            </td>
            <td>
              <TextInput
                className={
                  !errors.userFirstname
                    ? "input-floating input-border"
                    : "input-floating input-border-error"
                }
                label="Votre prénom"
                type="text"
                value={userFirstname}
                disabled={false}
                rightIcon={false}
                requiredMention={true}
                handleChangeParent={handleChangeUserFirstname}
              ></TextInput>
              {errors.userFirstname && (
                <Errors
                  className="error-message"
                  backgroundColor={"rgba(233, 75, 50, 0.08)"}
                  textColor={"#e94b32"}
                  message={"Vous devez renseigner un prénom"}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="input-container-message">
        <TextInput
          className="input-border textarea"
          label="Laissez-lui un petit message"
          type="message"
          value={message}
          disabled={false}
          handleChangeParent={handleChangeMessage}
        ></TextInput>
        {errors.message && (
          <Errors
            className="error-message"
            backgroundColor={"rgba(233, 75, 50, 0.08)"}
            textColor={"#e94b32"}
            message={"Vous devez renseigner un message"}
          />
        )}
        {errors.submit && (
          <Errors
            className="error-message"
            backgroundColor={"rgba(233, 75, 50, 0.08)"}
            textColor={"#e94b32"}
            message={
              "Une erreur est survenue. Merci de réessayer ultérieurement"
            }
          />
        )}
        {successMessageDisplayed && (
          <Success message="Votre message a bien été envoyé" />
        )}
      </div>

      <button
        className="btn-send-form "
        type="button"
        onClick={(e) => submitShareMessageForm(e)}
      >
        <span>Envoyer</span>
      </button>
    </form>
  );
}
